import Vue from 'vue'
import VueRouter from 'vue-router'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home.vue'),
  },
  {
    path: '/region/:code',
    name: 'Region',
    component: () => import('../views/region.vue'),
  },
  {
    path: '/czn/:id',
    name: 'CZN',
    component: () => import('../views/czn.vue'),
  },


]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
